<template>
  <div class="pay_password">
    <!-- 导航栏 修改支付密码-->
    <van-nav-bar :title="$t('withdraw.paymentUnlockPassword')"
      :class="!$isLinkGame ? 'black-bc yellow-color' : 'red-bc white-color'">
      <i slot="left" class="iconfont icon-jiantou1" @click="$router.back()"
        :color="!$isLinkGame ? '#ffd400' : '#fff'"></i>
      <template #title>
        <span :class="!$isLinkGame ? 'yellow-color' : 'white-color'">{{ $t('withdraw.paymentUnlockPassword') }}</span>
      </template>
    </van-nav-bar>

    <van-form @submit="onSubmit">
      <!-- language-ch 请输入4位数字为支付密码 -->
      <van-field v-model="formData.newpass" type="password" name="newpass" :label="$t('withdraw.keyPassword')"
        :placeholder="$t('withdraw.pleaseDigitsPaymentPassword')" :rules="[
          {
            required: true,
            message: $t('withdraw.pleaseDigitsPaymentPassword'),
          },
        ]" />

      <!-- 请求验证码 -->
      <van-field class="code" v-model="formData.code" name="code" clearable :label="$t('withdraw.SMSCodeConfirmation')"
        :placeholder="$t('withdraw.pleaseVerificationCode')" :rules="[
          { required: true, message: $t('withdraw.pleaseVerificationCode') },
        ]">
        <template #button>
          <van-count-down :time="1000 * 60" format="ss s" v-if="isCountDownShow" @finish="isCountDownShow = false" />
          <van-button round class="send-sms-btn" size="small" type="default" native-type="button" v-else
            @click="onSendSms">
            {{ registeinfo }}</van-button>
        </template>
      </van-field>
      <div class="list-phone">
        {{ $t('withdraw.phoneNumberCodeReceived') }} :{{ mobile }}
      </div>

      <div style="margin: 16px">
        <van-button block type="danger" native-type="submit">{{ $t('withdraw.submitInformation') }}</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { sendSms, submitPassword, userInfo } from '@/api/use.js'
export default {
  name: '',
  components: {},
  data() {
    return {
      formData: {
        newpass: '',
        code: '',
      },
      mobile: '',
      // 控制是否显示倒计时
      isCountDownShow: false,
      //  点击信息的提示
      registeinfo: this.$t('withdraw.getConfirmationCode'),
      usermobile: '',
    }
  },
  mounted() { },
  created() {
    this.getUserInfo()
  },
  methods: {
    async getUserInfo() {
      const {
        data: { data, ret },
      } = await userInfo()
      if (ret === 1) {
        this.mobile =
          data.userphone.length === 11
            ? data.userphone.substr(0, 3) + '****' + data.userphone.substr(7)
            : data.userphone.substr(0, 3) + '***' + data.userphone.substr(7)
      }
      this.usermobile = data.userphone
    },
    // 1、点击获取短信事件
    async onSendSms() {
      this.registeinfo = this.$t('withdraw.isAccessTo')
      // 调用发送验证码请求的异步请求
      if (this.usermobile) {
        
        // 如果手机号验证成功，让倒计时显示
        this.isCountDownShow = true
        try {
          const { data } = await sendSms({
            userphone: this.usermobile,
            must: 2,
          })
          data.ret === 1
            ? this.$toast(this.$t('withdraw.theObtainedSuccessfully'))
            : this.$toast(this.$t('withdraw.failedChangePassword'))
        } catch (error) {
          this.isCountDownShow = false
        }
      } else this.$toast(this.$t('withdraw.pleaseMobilePhoneNumber'))
      this.registeinfo = this.$t('withdraw.getCode')
    },
    async onSubmit() {
      try {
        const { data } = await submitPassword(this.formData)
        
        
        if (data.ret === 1) {
          this.$toast(this.$t('withdraw.paymentPasswordModifiedSuccessfully'))
          this.$router.push({
            path: '/withdraw',
            query: { paypassword: false },
          })
          return
        }
        this.$toast(data.msg)
      } catch (error) {
        this.$toast(this.$t('withdraw.pleaseTryAgainLater'))
      }
    },
  },
}
</script>

<style lang="less" scoped>
.pay_password {
  font-family: 'PingFang-Regular';

  .van-nav-bar {
    /deep/ .van-nav-bar__content {
      // background-color: #ff0000;
    }

    /deep/ .van-nav-bar__title {
      // color: #fff;
    }

    .iconfont {
      font-size: 44px;
      // color: #fff;
    }
  }

  .van-form {
    margin: 15px 0;
    color: #000;

    /deep/ .van-field {
      font-size: 22px;

      /deep/ .van-field__label {
        width: 265px !important;
      }

      /deep/ .van-field__body {
        input {
          text-align: center;
        }
      }
    }

    .code {
      /deep/ .van-field__label {
        width: 180px !important;
      }

      .send-sms-btn {
        width: 152px;
        height: 46px;
        line-height: 46px;
        background-color: #fff;
        font-size: 22px;
        color: #ff0000;
        border: none;
      }
    }

    .list-phone {
      text-align: right;
      margin-top: 24px;
      margin-right: 24px;
      font-size: 24px;
      color: #666666;
    }
  }
}
</style>
